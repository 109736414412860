<template>
    <CContainer>
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader>
                        <h4 class="m-0">{{ $t('pages.content.linkManagement.headers.create') }}</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                            <validation-provider
                                :name="$t('pages.content.linkManagement.form.name', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.linkManagement.form.name', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-input
                                        v-model="form.name.de"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.linkManagement.form.name', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, min: 3, max: 32 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.linkManagement.form.name', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-input
                                        v-model="form.name.en"
                                        maxlength="32"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="name-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="name-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.linkManagement.form.description', { language: $t('generally.languages.de') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.linkManagement.form.description', { language: $t('generally.languages.de') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.de"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-de"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-de">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.linkManagement.form.description', { language: $t('generally.languages.en') })"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.linkManagement.form.description', { language: $t('generally.languages.en') })"
                                >
                                    <b-form-textarea
                                        v-model="form.description.en"
                                        maxlength="2048"
                                        type="text"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="description-feedback-en"
                                    />
                                    <b-form-invalid-feedback id="description-feedback-en">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.vdi.apps.form.image').toString()"
                                :rules="{ required: false, max: 255 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                >
                                    <template #label>
                                        {{ $t('pages.content.linkManagement.form.image') }}
                                        <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.linkManagement.form.hints.image')"/>
                                    </template>
                                    <b-input-group>
                                        <b-input-group-prepend>
                                            <b-input-group-text class="bg-white">
                                                <img :src="form.image" v-show="picture" width="20" height="20" @load="picture = true" @error="picture = false">
                                                <b-icon-link45deg v-show="!picture" class="text-orange"/>
                                            </b-input-group-text>
                                        </b-input-group-prepend>
                                        <b-form-input
                                            type="url"
                                            name="image"
                                            maxlength="255"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.image"
                                            :placeholder="$t('pages.vdi.apps.form.image.placeholder')"
                                            aria-describedby="image-feedback"
                                        />
                                        <b-form-invalid-feedback id="image-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </validation-provider>
                            <validation-provider
                                :name="$t('pages.content.linkManagement.form.url')"
                                :rules="{ required: true, max: 2048 }"
                                v-slot="validationContext"
                            >
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="3"
                                    :label="$t('pages.content.linkManagement.form.url')"
                                >
                                    <b-form-input
                                        v-model="form.url"
                                        maxlength="2048"
                                        placeholder="https://example.com/examplePath/"
                                        type="url"
                                        :state="getValidationState(validationContext)"
                                        aria-describedby="url-feedback"
                                    />
                                    <b-form-invalid-feedback id="url-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                            <b-form-group
                                label-cols-sm="12"
                                label-cols-md="3"
                            >
                                <template #label>
                                    {{ $t('pages.content.linkManagement.form.public') }}
                                    <font-awesome-icon icon="info-circle" v-b-popover.hover.top="$t('pages.content.linkManagement.form.hints.public')"/>
                                </template>
                                <b-form-select v-model="form.public">
                                    <b-form-select-option :value="false">{{ $t('pages.content.linkManagement.values.public.no') }}</b-form-select-option>
                                    <b-form-select-option :value="true">{{ $t('pages.content.linkManagement.values.public.yes') }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t("buttons.create") }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
    </CContainer>
</template>

<script>
import linkService from "../../../services/linkService";

export default {
    name: "NewLink",
    data: () => ({
        resourceTypes: [],
        resources: [],
        form: {
            name: {
                de: '',
                en: ''
            },
            description: {
                de: '',
                en: ''
            },
            url: '',
            image: '',
            public: false
        },
        picture: false,
        loading: false
    }),
    mounted() {

    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form)
                data.name = JSON.stringify(data.name)
                data.description = JSON.stringify(data.description)
                linkService.add(data).then(() => {
                    this.$toastr.s(this.$t('pages.content.linkManagement.messages.success.created'));
                    this.$router.push({name: 'ContentLinkManagementList'});
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error);
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
