import api from "./api";

export default {
    api: 'links',
    /**
     * Get all links
     * @returns {Promise}
     */
    list(){
        return new Promise((resolve, reject) => {
            api().get(this.api).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Get link by id
     * @param {string|number} id Link ID
     * @returns {Promise}
     */
    get(id){
        return new Promise((resolve, reject) => {
            api().get(this.api + '/' + id).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Create new Link
     * @param {object} data Link object with all attr
     * @returns {Promise}
     */
    add(data) {
        return new Promise((resolve, reject) => {
            api().post(this.api, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Update link by id
     * @param {object} data Link object with all attr, includes id
     * @returns {Promise}
     */
    edit(data){
        return new Promise((resolve, reject) => {
            api().put(this.api + '/' + data.id, data).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
    /**
     * Delete one or more Links by id
     * @param {array} data Array of Link ids
     * @returns {Promise}
     */
    delete(data){
        return new Promise((resolve, reject) => {
            api().delete(this.api, { data: data}).then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    },
};
